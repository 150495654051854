import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { loading } from '../../actions/Loading';
import api from '../../api/api';
import { useDispatch } from 'react-redux';
import { AnnouncementCommentsResult } from '../../model/AnnouncementCommentsResult';
import CommentGroupItem from './CommentGroupItem';
import styled from 'styled-components';

interface IProps{
    combinedTicker:string
    match?: { params: {  combinedTicker:string, announcementGuid:string }};
}

const Wrapper = styled.div`
.comment-groups-container{

}

`;

    const EditAnnouncementComments: React.FC<IProps>= (props:IProps) => {
    const dispatch = useDispatch();
    const [ item, setItem ] = useState<AnnouncementCommentsResult>();
    
    useAsyncEffect(async (isMounted:()=>boolean)=> {
        let announcementGuid;
        let combinedTicker = props.combinedTicker;
        if(props.match){
            announcementGuid = props.match.params.announcementGuid;
            combinedTicker = props.match.params.combinedTicker;
        }
        
        dispatch(loading(true));
        const result = await api().getAnnouncementCommentsResult(combinedTicker, announcementGuid);
        if(isMounted()){
            setItem(result)   
        }
        dispatch(loading(false));
        
        
      }, []);

     

    return <div>

{ item && <Wrapper>
    <p>Comments are relevant for the <a href="https://web.investi.com.au/interactive-announcements/" target='_blank' rel="noreferrer">interactive announcements</a> widget</p>
<p>Total number of comments for {item.combinedTicker}: {item.commentGroups.length} </p>
<div className='comment-groups-container'>
{  item.commentGroups.map((x,i)=> <CommentGroupItem key={i} commentGroup={x}/>)}
</div>

</Wrapper>}

    </div>
}

export default EditAnnouncementComments