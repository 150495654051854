import React from 'react';
import {  Route, Switch } from 'react-router-dom';

import Dashboard from '../components/dashboard/Dashboard';
import AddStockPage from '../components/AddStockPage';
import EditStockPage from '../components/EditStockPage';
import NotFoundPage from '../components/NotFoundPage';
import Header from '../components/Header';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import SystemSettingsPage from '../components/SystemSettingsPage';
import ReportPage from '../components/report/ReportPage';
import styled from 'styled-components'
import XeroPage from '../components/XeroPage';
import NewOrderPage from '../components/servicesOrderForm/NewOrderPage';
import ServiceOrdersPage from '../components/servicesOrderForm/ServiceOrdersPage';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute';
import Login from '../components/Login';
import { IGlobalState } from '../model/IGlobalState';
import AnnouncementCommentResponsePage from '../components/announcementCommentResponse/AnnouncementCommentResponsePage';
import ClientDashboard from '../components/client/ClientDashboard';
import UsersPage from '../components/users/UsersPage';
import UserPage from '../components/users/UserPage';
import ClientStockItem from '../components/client/ClientStockItem';
import { HasAdminRole } from '../model/Roles';
import ReferersPage from '../components/referers/ReferersPage';
import EditAnnouncementComments from '../components/client/EditAnnouncementComments';




const Style = styled.div`
max-width:1200px;
margin:auto;
`;

const publicRoutes = [ '/report', '/new-order', '/login']

const AppRouter:React.FC = () => {
    const loading = useSelector((state:IGlobalState)=>state.loading);
    const user = useSelector((state:IGlobalState)=>state.user);
    const isAuthenticated = !!user.username;
    const routerProps: ProtectedRouteProps = {
      isAuthenticated,
        authenticationPath: '/login'        
      };
    
    const showHeader = isAuthenticated && publicRoutes.every(x=>!window.location.pathname.startsWith(x))

    const isAdmin = isAuthenticated && HasAdminRole(user)

    return <Style>
        
            { showHeader && <Header />}

            {loading && <div className="loading"><FaSpinner className="fa-spin" size={32} color="grey" /></div>}

            
              <Switch>

              <ProtectedRoute {...routerProps} path='/' component={isAdmin ? Dashboard:ClientDashboard} exact={true}/>
              
              <Route path="/report/:id" component={ReportPage} />                
                    
              <Route path="/new-order/:id?" component={NewOrderPage} />
              <Route path="/login" component={Login} />

              <ProtectedRoute {...routerProps} path="/create" component={AddStockPage} />
              <ProtectedRoute {...routerProps} path="/stocks/:id/:tab?" component={EditStockPage} />
              <ProtectedRoute {...routerProps} path="/system-settings" component={SystemSettingsPage} />
              <ProtectedRoute {...routerProps} path="/xero-page" component={XeroPage} />
              <ProtectedRoute {...routerProps} path="/orders" component={ServiceOrdersPage} />
              <ProtectedRoute {...routerProps} path="/users" component={UsersPage} exact={true}/>
              <ProtectedRoute {...routerProps} path="/users/:id" component={UserPage} />
              <ProtectedRoute {...routerProps} path="/referers" component={ReferersPage} />

              <ProtectedRoute {...routerProps} path="/AnnouncementCommentResponse/:id" component={AnnouncementCommentResponsePage} />          
              <ProtectedRoute {...routerProps} path="/client-stocks/:combinedTicker" component={ClientStockItem} />
              <ProtectedRoute {...routerProps} path="/AnnouncementComments/:combinedTicker/:announcementGuid?" component={EditAnnouncementComments} />


              <Route component={NotFoundPage} />
              
              </Switch>
           
        
    </Style>
}


 export default AppRouter
